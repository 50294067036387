<template>
  <div class="wrap" style="height: 100vh;background: #F8F8F8;">
 	</div>
</template>
<script>
	export default {
		name: 'selfRepaymentSuccess',
		data() {
     	return {
			}
		},
		created() {
		},
		mounted() {
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.src = 'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js';
			document.head.appendChild(s);
			let mchData ={
				action:'onIframeReady',
				displayStyle:'SHOW_OFFICIAL_PAGE'
			}
			let postData = JSON.stringify(mchData)
			parent.postMessage(postData, 'https://payapp.weixin.qq.com')
		},
		
	}
    
</script>

